import { RestLink } from 'apollo-link-rest';

export const restLink = new RestLink({
  uri: '/auth/',
  credentials: 'same-origin'
});

export const coreRestLink = (csrftoken: string) =>
  new RestLink({
    uri: '/api/rest/',
    credentials: 'same-origin',
    bodySerializers: {
      fileEncode: (data: any, headers: Headers) => {
        let formData;
        if (data instanceof FormData) {
          // If we received a FormData, use it
          formData = data;
        } else {
          // If we just received a file, wrap it in FormData
          formData = new FormData();
          formData.append('file', data, data.name);
        }
        headers.set('X-CSRFToken', csrftoken);
        return { body: formData, headers };
      }
    }
  });
