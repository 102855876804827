import classNames from 'classnames';
import { Button, List, Popover, Tooltip } from 'reablocks';
import { MouseEventHandler, ReactElement } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import css from './Nav.module.css';

// Default Popover/Tooltip position modifiers
const MODIFIERS = {
  offset: {
    offset: '5px, 5px'
  }
};

/**
 * Renders a root-level menu item (ie. an icon button).
 *
 * That button can be optionally be wrapped in either a:
 * - link (for a single-option menu item)
 * - pop-over (for a menu item with a sub-menu)
 *
 * Either way, it will also be wrapped in a tooltip, so that the user can see
 * the menu item's name when they hover over it.
 *
 */
const MenuItem = ({
  buttonClassName,
  disablePadding = false,
  isActivePatterns = [],
  icon,
  link,
  name,
  modifiers = MODIFIERS,
  onClick,
  submenuClass,
  submenu,
  triggerClassName
}: {
  /** Class to add to the generated <Button> */
  buttonClassName?: string;

  /** Passed directly to ReaBlocks Button */
  disablePadding?: boolean;

  /** An element for the button's icon (presumably an SVG) */
  icon: ReactElement;

  /**
   * The menu item will become "active" if any of these match the current URL
   */
  isActivePatterns?: string[];

  /** The path of the menu item's link */
  link?: string;

  /** The modifiers to pass to the generated ReaBlocks Popover/Tooltip */
  modifiers?: {
    offset: {
      offset: string;
    };
  };
  /** The tooltip to display, and also the name of the sub-menu (if any) */
  name?: string;

  /**
   * If (instead linking to a page) the item triggers custom logic when clicked,
   * this prop specifies that logic
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /** If the item has a sub-menu, this sets the class of that submenu */
  submenuClass?: string;

  /**
   * If the item has a sub-menu, this prop contains its React elements
   * (which presumably will be made up of SubmenuItem/UserSubmenuItem tags)
   */
  submenu?: ReactElement;

  /* A classname to add to the generated tooltip's "trigger" element */
  triggerClassName?: string;
}) => {
  const { pathname } = useLocation();
  const matchesCurrentPath = pattern => matchPath(pattern, pathname);
  const isActive = isActivePatterns.some(matchesCurrentPath);

  // Start with a plain old <Button/>
  let button = (
    <Button
      className={classNames(css.navBtn, buttonClassName, {
        [css.active]: isActive
      })}
      variant="text"
      {...{ disablePadding, onClick }}
    >
      {icon}
    </Button>
  );

  if (name) {
    // If we have a tooltip name, wrap the button in a tooltip
    button = (
      <Tooltip
        content={name}
        placement="right-start"
        modifiers={modifiers}
        triggerClassName={triggerClassName}
      >
        {button}
      </Tooltip>
    );
  }

  if (submenu) {
    // If we have a submenu, wrap the (tooltip-wrapped) button in a popover also
    button = (
      <Popover
        className={css.popover}
        closeOnClick
        content={() => (
          <div className={classNames(css.menu, submenuClass)}>
            <List>
              <h2>{name}</h2>
              {submenu}
            </List>
          </div>
        )}
        placement="right-start"
        modifiers={modifiers}
      >
        {button}
      </Popover>
    );
  }

  if (link) {
    // If this is a single-page menu item, wrap it in a link to its page
    button = <Link to={link}>{button}</Link>;
  }

  return button;
};

export default MenuItem;
