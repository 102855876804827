import { gql, useQuery } from '@apollo/client';
import { FilterTechniques } from 'App/Techniques/FilterTechniques';
import TechniquesActiveFilters from 'App/Techniques/TechniquesActiveFilters';
import { ORDER_BY_FIELDS } from 'App/Techniques/utils';
import classNames from 'classnames';
import { PrioritizedTechniqueTypeConnection } from 'core/graphql/graphql';
import { captureEvent } from 'core/utils/capture';
import { useFilterPager } from 'core/utils/useFilterPager';
import camelCase from 'lodash/camelCase';
import { Loader } from 'shared/elements/Loader';
import { SearchInput } from 'shared/form/SearchInput';
import { extractNodes } from 'shared/utils/GraphUtil';
import AttackMatrix from './AttackMatrix';
import css from './AttackMatrix.module.css';

const RAW_TECHNIQUES_QUERY = `
query TechniquesQuery(
  $pageSize: Int!
  $offset: Int! = 0
  $domainName: String!
  $orderBy: String!
  $searchNameOrMitreId: String! = ""
  $tacticNames: String! = ""
  $integrationNames: String! = ""
  $platformNames: String! = ""
  $priority: String! = ""
  $content: String! = ""
  $mitreIds: String! = ""
  $campaignStixIds: String! = ""
  $softwareMitreIds: String! = ""
  $threatGroupMitreIds: String! = ""
  $telemetrySubcategoryNames: String! = ""
  $controlStixIds: String! = ""
  $telemetrySubcategoryId: ID! = ""
) {
  platformNames
  tacticNames
  integrationNames
  allPrioritizedTechniques(
    first: $pageSize
    offset: $offset
    domainName: $domainName
    orderBy: $orderBy
    search: $searchNameOrMitreId
    tacticNames: $tacticNames
    integrationNames: $integrationNames
    platformNames: $platformNames
    priority: $priority
    content: $content
    mitreIds: $mitreIds
    campaignStixIds: $campaignStixIds
    softwareMitreIds: $softwareMitreIds
    threatGroupMitreIds: $threatGroupMitreIds
    telemetrySubcategoryNames: $telemetrySubcategoryNames
    controlStixIds: $controlStixIds
    telemetrySubcategoryId: $telemetrySubcategoryId
  ) {
    totalCount
    edges {
      node {
        # Used for keys
        id

        # Displays in each technique box
        name

        # Used for priority icon in each technique
        priority

        # One of these three sets each technique's left border color
        content
        detectionsPercentage
        telemetryPercentage

        # Used in the URL for each technique's link
        mitreId

        # Used to sort the techniques into groups
        tacticNames
      }
    }
  }
}
`;
const TECHNIQUES_QUERY = gql(RAW_TECHNIQUES_QUERY);

/** Renders the legend explaining the colors in the page's upper-right area */
const CoverageLegend = () => (
  <div className={css.coverageLegend}>
    <h2>Coverage</h2>
    {[`Full`, `Strong`, `Moderate`, `Weak`, `Poor`].map(color => (
      <div key={color} className={css.color}>
        <div className={classNames(css.colorBox, css[camelCase(color)])} />
        <span className={css.colorText}>{color}</span>
      </div>
    ))}
  </div>
);

interface TechniquesQueryResult {
  tacticNames: string[];
  integrationNames: string[];
  platformNames: string[];
  allPrioritizedTechniques: PrioritizedTechniqueTypeConnection;
}

const AttackMatrixContainer = () => {
  const props = useFilterPager({
    defaultSort: { field: ORDER_BY_FIELDS.PRIORITY, direction: 'desc' }
  });
  const variables = {
    pageSize: props?.pageSize,
    domainName: 'enterprise-attack',
    offset: props.page * props?.pageSize || 0,
    orderBy: `${props.sort.direction === 'asc' ? '' : '-'}${props.sort.field}`,
    searchNameOrMitreId: props.keyword,
    tacticNames: props.filter?.tacticNames?.toLocaleString(),
    integrationNames: props.filter?.integrationNames?.toLocaleString(),
    platformNames: props.filter?.platformNames,
    priority: props.filter?.priority,
    content: props.filter?.coverage,
    mitreIds: props.filter?.techniqueMitreIds,
    campaignStixIds: props.filter?.campaignStixIds,
    softwareMitreIds: props.filter?.softwareMitreIds,
    threatGroupMitreIds: props.filter?.threatGroupMitreIds,
    telemetrySubcategoryNames: props.filter?.telemetrySubcategoryNames,
    controlStixIds: props.filter?.controlStixIds,
    telemetrySubcategoryId: props.filter?.telemetrySubcategoryId
    // selectedCoverage only impacts the client, not the query
  };
  const { loading, data } = useQuery<TechniquesQueryResult>(TECHNIQUES_QUERY, {
    variables
  });

  if (loading) {
    return <Loader />;
  }

  const {
    integrationNames,
    platformNames,
    allPrioritizedTechniques,
    tacticNames
  } = data;

  const techniques = extractNodes(allPrioritizedTechniques);
  const { filter, savedFilterId, setFilter, setSavedFilterId } = props;
  const { selectedCoverage } = filter ?? { selectedCoverage: 'content' };

  return (
    <div className={css.attackMatrix}>
      <div className={css.titleBar}>
        <h1>MITRE ATT&CK® Matrix</h1>
        <div className={css.coverageDetails}>
          <CoverageLegend />
        </div>
        <div className={css.searchAndFilter}>
          <SearchInput
            value={props.keyword}
            placeholder="Search by Name or ID"
            onValueChange={keyword => {
              props.setKeyword(keyword);
              captureEvent('search:techniques', { keyword });
            }}
          />
          <FilterTechniques
            allPlatformNames={platformNames}
            allTacticNames={tacticNames}
            allIntegrationNames={integrationNames}
            filters={filter}
            hasSelectedCoverage={true}
            onFilterChange={setFilter}
            savedFilterId={savedFilterId}
            setSavedFilterId={setSavedFilterId}
          />
        </div>
      </div>

      <div className={css.pageHeader}>
        <div></div>
      </div>

      <TechniquesActiveFilters
        {...{ className: css.activeFilters, filter, setFilter }}
      />
      <AttackMatrix {...{ selectedCoverage, techniques }} />
    </div>
  );
};

export default AttackMatrixContainer;
