import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { Kbd, List, ListItem } from 'reablocks';
import { FC, useCallback } from 'react';
import { HotkeyShortcuts, MODIFIER_KEY, useHotkeys } from 'reakeys';
import css from './HotkeyCombos.module.css';

export const HotkeyCombos: FC = () => {
  const hotkeys = useHotkeys();
  const categories = groupBy(hotkeys, 'category');

  const sorted: { [key: string]: HotkeyShortcuts[] } = Object.keys(
    categories
  ).reduce((prev, cur) => {
    const category = sortBy(categories[cur], 'name');
    const label = cur === 'undefined' ? 'General' : cur;

    return {
      ...prev,
      [label]: category.filter(k => !k.hidden)
    };
  }, {});
  const { General, ...rest } = sorted;
  const others = sortBy(Object.keys(rest || {}));

  const renderKeyCode = useCallback(keyCode => {
    const wrapped = Array.isArray(keyCode) ? keyCode : [keyCode];
    const formatted = wrapped.map(k => k.replace('mod', MODIFIER_KEY));

    return (
      <div className={css.combos}>
        {formatted.map((k, i) => (
          <Kbd key={i} className={css.combo} keycode={k} />
        ))}
      </div>
    );
  }, []);

  const renderGroups = useCallback(
    group => {
      if (!sorted[group] || !sorted[group].length) {
        return null;
      }

      return (
        <section key={group}>
          <h3>{group}</h3>
          <List>
            {sorted[group].map(kk => (
              <ListItem
                key={kk.name}
                disableGutters
                disablePadding
                className={css.comboContainer}
                end={renderKeyCode(kk.keys)}
              >
                <label>{kk.name}</label>
                {kk.description && <p>{kk.description}</p>}
              </ListItem>
            ))}
          </List>
        </section>
      );
    },
    [renderKeyCode, sorted]
  );

  return (
    <div className={css.groups}>
      {renderGroups('General')}
      {others.map(renderGroups)}
    </div>
  );
};
