import { gql, useMutation, useQuery } from '@apollo/client';
import { USERS_CONTEXT } from 'core/Apollo';
import { ChildTenant, ListChildrenResponse } from 'core/graphql/graphql';
import sortBy from 'lodash/sortBy';
import { useNotification } from 'reablocks';
import { useState } from 'react';
import useAsyncEffect from 'shared/hooks/useAsyncEffect';
import { handleSimpleMutationResponse } from 'shared/utils/GraphUtil';
/**
 * Because it comes from FusionAuth, tenant objects don't look like our other
 * objects, so we convert them.  This (DomainRegistration) interface defines a
 * raw FusionAuth object, while Organization (below) defines a parsed version.
 *
 * NOTE: This isn't *quite* the same thing as a Tenant: the former comes from
 *       FusionAuth and has "tenant_id", while the latter comes from our GraphQL
 *       API and has "tenantId".  Both represent the same conceptual org though.
 */
interface DomainRegistration {
  client_id: string;
  tenant_name: string;
  tenant_id: string;
}
/**
 * The client-side version of a DomainRegistration/Tenant
 */
export interface Organization {
  clientId?: string; // Only if it came from Fusion Auth
  emailDomains?: string[]; // Only if it came from GraphQL
  id: string;
  name: string;
}

// Create mutation
const createChildTenantMutation = gql`
  mutation CreateOrganization($tenant: CreateChildTenant) {
    createChildTenant(input: $tenant) {
      success
      message
    }
  }
`;

export type NewChildTenant = Omit<ChildTenant, 'tenantId'>;

const convertOrganizationToTenant = (
  organization: Organization
): ChildTenant => {
  const tenant = { ...organization, tenantId: organization.id };
  delete tenant.id;
  return tenant;
};

const convertTenantToOrganization = ({
  emailDomains,
  name,
  tenantId
}: ChildTenant): Organization => ({ emailDomains, id: tenantId, name });

/**
 * Hook for generating an organization creation function
 */
export const useCreateChildOrganization = () => {
  const { notifyError } = useNotification();
  const [createChildTenant] = useMutation<{ createChildTenant: ChildTenant }>(
    createChildTenantMutation,
    { ...USERS_CONTEXT, refetchQueries: [organizationsQuery] }
  );
  /**
   * Creates a new child tenant (ie. "organization") on the server
   */
  const create = async (organization: Organization): Promise<boolean> => {
    // Convert the Or
    const tenant = convertOrganizationToTenant(organization);

    const { data, errors } = await createChildTenant({ variables: { tenant } });
    return handleSimpleMutationResponse(
      'createChildTenant',
      data,
      errors,
      notifyError
    );
  };
  return create;
};

// Update mutation
const updateChildOrganizationMutation = gql`
  mutation UpdateChildOrganization($tenant: UpdateChildTenant) {
    updateChildTenant(input: $tenant) {
      success
      message
    }
  }
`;

/**
 * Hook for generating an organization updating function
 */
export const useUpdateOrganization = () => {
  const { notifyError } = useNotification();
  const [updateChildTenant] = useMutation(updateChildOrganizationMutation, {
    ...USERS_CONTEXT,
    refetchQueries: [organizationsQuery]
  });
  /**
   * Updates an existing child tenant (ie. "organization") on the server
   */
  const update = async (organization: Organization): Promise<boolean> => {
    const tenant = convertOrganizationToTenant(organization);
    const variables = { tenant };
    const { data, errors } = await updateChildTenant({ variables });
    return handleSimpleMutationResponse(
      'updateChildTenant',
      data,
      errors,
      notifyError
    );
  };
  return update;
};

/** Get the parent organizations that the user (with "email") belongs to */
export const fetchParentOrganizations = async (
  email: string
): Promise<Organization[]> => {
  if (!email) {
    return null;
  }
  try {
    const response = await fetch('/auth/v2/client', {
      body: JSON.stringify({ email }),
      method: 'POST'
    });
    const { domain_registrations } = await response.json();
    return domain_registrations.map(
      ({ tenant_name, tenant_id, client_id }) => ({
        clientId: client_id,
        id: tenant_id,
        name: tenant_name
      })
    );
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * Returns all of the user's parent organizations (so that we can let them pick
 * which one to login to on the second login page, or display them on the
 * Organizations page).
 * @see https://room40labs.atlassian.net/wiki/spaces/IN/pages/434634760/Front-End+Processes#Organization-(Multi-Tenant)-API
 */
export const useParentOrganizations = (
  email: string,
  isSorted: boolean = false
) => {
  const [organizations, setOrganizations] = useState<Organization[]>();
  useAsyncEffect(async () => {
    if (!email) {
      return;
    }
    let organizations = await fetchParentOrganizations(email);
    if (!organizations) {
      return;
    }
    if (isSorted) {
      organizations = sortBy(organizations, 'name');
    }
    setOrganizations(organizations);
  }, [email]);
  return organizations;
};

/**
 * Returns the parent organization that the user is currently logged into
 * @param email the user's email
 * @param tenantId the user's tenantId (ie. organization ID)
 */
// TODO: Get rid of this version; we have a copy in useOrganizations
export const useCurrentParentOrganization = (
  email: string,
  tenantId: string
) => {
  const parentOrgs = useParentOrganizations(email);
  return parentOrgs?.find(({ id }) => id === tenantId);
};

// List query
const organizationsQuery = gql`
  query ListOrganizations {
    tenantChildren {
      children {
        emailDomains
        name
        tenantId
        __typename
      }
    }
  }
`;

/**
 * (Main) hook, for retrieving a list of organizations
 * @param skip - if provided no data will be fetched
 */
export const useChildOrganizations = (skip: boolean) => {
  const { data } = useQuery<{ tenantChildren: ListChildrenResponse }>(
    organizationsQuery,
    { ...USERS_CONTEXT, skip }
  );
  const tenants = data?.tenantChildren?.children;
  return tenants?.map(convertTenantToOrganization) ?? null;
};
