import { ReactComponent as PageLeftArrowIcon } from 'assets/icons/pageLeftArrow.svg';
import { ReactComponent as PageRightArrowIcon } from 'assets/icons/pageRightArrow.svg';
import classNames from 'classnames';
import { MotionGroup, MotionItem } from 'reablocks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PriorityIcon from 'shared/icons/PriorityIcon';
import { getCoverageLevel } from 'shared/theme/useContentColors';
import useWindowDimensions from 'shared/utils/useWindowDimensions';
import css from './AttackMatrix.module.css';

const TACTIC_NAMES = [
  'Initial Access',
  'Execution',
  'Persistence',
  'Privilege Escalation',
  'Defense Evasion',
  'Credential Access',
  'Discovery',
  'Lateral Movement',
  'Collection',
  'Command and Control',
  'Exfiltration'
];

/** A single technique in the matrix */
const Technique = ({ selectedCoverage, technique }) => {
  const coverageValue = technique[selectedCoverage];
  const coverageLevel = getCoverageLevel(coverageValue).toLowerCase();
  return (
    <div key={technique.id} className={css.technique}>
      <div className={classNames(css.leftBorder, css[coverageLevel])}>
        <Link className={css.name} to={`/techniques/${technique.mitreId}`}>
          {technique.name}
        </Link>
        <div className={css.priority}>
          <PriorityIcon priority={technique.priority} /> {technique.priority}
        </div>
      </div>
    </div>
  );
};

/** Renders the Attack Matrix (the heart of the page) */
const AttackMatrix = ({ selectedCoverage, techniques }) => {
  const [columnIndex, setColumnIndex] = useState(0);
  const windowSize = useWindowDimensions();

  // Figure out how many columns we can fit: space - arrow space, divided by 180
  const columnCount = Math.floor((windowSize.width - 189) / 180);
  const lastColumnIndex = columnIndex + columnCount;
  const showLeftArrow = columnIndex > 0;
  const showRightArrow = TACTIC_NAMES.length - columnCount - columnIndex > 0;

  // NOTE: We can't use Object.groupBy because techniques can have multiple
  //       tactic names (and groupBy only lets us add them to one group)
  const techniquesByTactic = techniques.reduce((byTactic, technique) => {
    for (const tacticName of technique.tacticNames) {
      byTactic[tacticName] ||= [];
      byTactic[tacticName].push(technique);
    }
    return byTactic;
  }, {});

  const shiftColumnsLeft = () => setColumnIndex(Math.max(columnIndex - 1, 0));
  const shiftColumnsRight = () =>
    setColumnIndex(Math.min(columnIndex + 1, TACTIC_NAMES.length));
  return (
    <div className={css.matrix}>
      <div className={css.divider}>
        <div className={css.dividerBox} />
        <div className={css.dividerLine} />
        <div className={css.dividerBox} />
      </div>
      {showLeftArrow && (
        <div className={css.paginationArrow}>
          <PageLeftArrowIcon onClick={shiftColumnsLeft} />
        </div>
      )}
      {TACTIC_NAMES.map(
        (tactic, i) =>
          i >= columnIndex &&
          i < lastColumnIndex && (
            <div key={tactic}>
              <div className={css.header}>{tactic}</div>
              <MotionGroup>
                <div className={css.count}>
                  {techniquesByTactic[tactic]?.length} Techniques
                </div>
                {techniquesByTactic[tactic]?.map(technique => (
                  <MotionItem key={technique.id}>
                    <Technique {...{ selectedCoverage, technique }} />
                  </MotionItem>
                ))}
              </MotionGroup>
            </div>
          )
      )}
      {!!showRightArrow && (
        <div className={css.paginationArrow}>
          <PageRightArrowIcon onClick={shiftColumnsRight} />
        </div>
      )}
    </div>
  );
};

export default AttackMatrix;
