import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { User } from '@sentry/react';
import classNames from 'classnames';
import { ListItem } from 'reablocks';
import { ReactElement } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Avatar } from 'shared/elements/Avatar';
import css from './Nav.module.css';

type MuiIcon = OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
  muiName: string;
};
type SvgIcon = React.FC<React.SVGProps<SVGSVGElement>>;

export interface SubmenuItemProps {
  /** Should this sub-menu item always appear white? (default color is gray) */
  alwaysWhite?: boolean;
  /** The sub-menu item's icon */
  icon: MuiIcon | SvgIcon;
  /** The sub-menu item's visual text label */
  label: string;
  /** If the sub-menu item goes somewhere when clicked, this specifies where */
  link?: string;
  /** If the sub-menu item triggers custom JS when clicked, specify that JS */
  onClick?: any;
  /** The keyboard shortcut for the menu item (as React elements) */
  shortcut?: ReactElement;
}

/**
 * Renders a sub-menu item (ie. an icon button) in the Nav menu.  That button
 * will be wrapped in a link, to navigate to the appropriate page when the
 * sub-menu item is clicked.  Also, when the user is on an item's page, the
 * item's text/icon becomes white ("active").
 */
const SubmenuItem = ({
  alwaysWhite,
  icon: Icon,
  label,
  link,
  onClick,
  shortcut
}: SubmenuItemProps) => {
  // There are two chat pages, /aichat/welcome and /aichat/conversation; both
  // should "activate" the chat menu item
  const pattern = link?.startsWith('aichat') ? `aichat/*` : `${link}/*`;
  const isActive = !!useMatch(pattern);
  return (
    <Link {...{ onClick, to: link }}>
      <ListItem
        start={<Icon fontSize="large" />}
        className={classNames(css.listItem, {
          [css.active]: isActive,
          [css.alwaysWhite]: alwaysWhite
        })}
        end={shortcut}
      >
        {label}
      </ListItem>
    </Link>
  );
};

/**
 * One sub-menu item is completely different from the rest: the user sub-menu.
 * Rather than try to shoehorn it into our SubmenuItem component, we just have
 * a (completely separate, except for a className) component for it.
 */
export const UserSubmenuItem = ({ user }: { user: User }) =>
  user ? (
    <ListItem className={css.userListItem}>
      <Avatar size={40} className={css.avatar} user={user} />
      <h3>
        {user.firstName} {user.lastName}
        <br />
        <small className={css.email}>
          <a href={`mailto:${user.email}`}>{user.email}</a>
        </small>
      </h3>
    </ListItem>
  ) : null;

export default SubmenuItem;
