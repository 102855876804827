import { useTheme } from 'reablocks';

const COVERAGE_LEVELS = [
  'Full',
  'Strong',
  'Moderate',
  'Weak',
  'Poor',
  'Not Applicable'
];

export type CoverageLevel = (typeof COVERAGE_LEVELS)[number];

/**
 * Takes a numeric content value and returns a string indicating which "level"
 * of content coverage it represents (eg. 'Poor' or 'Strong').
 */
export const getCoverageLevel = (
  content: number | undefined | null
): CoverageLevel => {
  if (content === undefined || content === null) {
    return 'Not Applicable';
  }
  if (content >= 0 && content < 20) {
    return 'Poor';
  }
  if (content < 40) {
    return 'Weak';
  }
  if (content < 60) {
    return 'Moderate';
  }
  if (content < 80) {
    return 'Strong';
  }
  return 'Full';
};

/**
 * Returns a content levels => colors map (ie. object) from our theme's pallete.
 * Content levels can be obtained through the getCoverageLevel function
 * (returned in the same object).
 *
 * Alternatively the returned getCoverageLevelColor function can be used to
 * go straight to the color from a content score (skipping the level string).
 */
const useContentColors = () => {
  const { colors } = useTheme();
  const colorMap = {
    Full: colors.green[600],
    Strong: colors.green[800],
    Moderate: colors.slate[100],
    Weak: colors.slate[200],
    Poor: colors.red[300],
    'Not Applicable': colors.gray[600]
  };

  const getCoverageLevelColor = content => colorMap[getCoverageLevel(content)];

  return {
    getCoverageLevel,
    getCoverageLevelColor,
    ...colorMap
  };
};
export default useContentColors;
