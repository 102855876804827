import { useApolloClient } from '@apollo/client';
import { IFusionAuthContext } from '@fusionauth/react-sdk';
import {
  Organization,
  useChildOrganizations,
  useParentOrganizations
} from 'App/Settings/Organizations/OrganizationHooks';
import { useAuth } from 'core/Auth/AuthContext';
import { User } from 'core/graphql/graphql';
import { useNotification } from 'reablocks';
import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useEffect,
  useState
} from 'react';
import assert from 'shared/utils/assert';
import { buildUseContext } from 'shared/utils/contextUtil';

const localStorageKey = '@interpres:selected-organization-id';

/**
 * Determines what to tell the user after they try to switch organzations
 * @param newName - name of the organization the user is switching to
 * @param mainName - name of the user's main/parent organization
 * @param oldName - name of organization the user was on (before switching)
 */
// NOTE: Only exported for testing
export const getSwitchMessage = (mainName, selectedName: string): string => {
  // If we're switching to the main org we don't use it's name, we use null
  // (and we don't bother notifying the user, since that's the default)
  const name = selectedName === mainName ? null : selectedName;

  if (selectedName && selectedName !== mainName) {
    return `Now viewing only ${name} data`;
  }
};

/**
 * When the user has multiple tabs open, we can wind up with the following
 * scenario:
 * 1. User (in both tabs) is viewing parent data
 * 2. User switches to child org X in tab #1; in tab #1 they see X data
 * 3. But, in tab #2, the user still sees teh parent data
 *
 * This hook solves that by setting up an event listener which updates (this
 * tab's) selected org ID whenever the local storage entry for the selected org
 * ID changes in another tab.
 */
const useUpdateWhenStorageChangesInAnotherTab = setSelectedOrganizationId => {
  const client = useApolloClient();
  useEffect(() => {
    addEventListener('storage', event => {
      if (event.key === localStorageKey) {
        // When the local storage gets updated in another tab, we need to update
        // our state variable (in this tab)
        setSelectedOrganizationId(JSON.parse(event.newValue));
        location.reload();
      }
    });
  }, []);
};

/**
 * This hook simply notifies the user that they are on a specific child
 * organization (when they switch to it, or when they reload the page).
 */
const useInformUserOfChildOrganizationOnReload = (
  mainOrganization: Organization,
  selectedOrganizationId: string | undefined,
  childOrganizations: Organization[]
) => {
  const { notifySuccess } = useNotification();
  const [hasInformedUser, setHasInformedUser] = useState(false);
  useEffect(() => {
    if (
      hasInformedUser ||
      !childOrganizations ||
      !mainOrganization ||
      !selectedOrganizationId
    ) {
      return;
    }

    const selectedOrganization = childOrganizations?.find(
      ({ id }) => id === selectedOrganizationId
    );
    if (selectedOrganization) {
      notifySuccess(
        getSwitchMessage(mainOrganization.name, selectedOrganization.name)
      );
      setHasInformedUser(true);
    }
  }, [childOrganizations, mainOrganization, selectedOrganizationId]);
};

// TODO: I didn't want to break too many things at once, so I kept AuthContext
//       as its own separate context for now.  However, both practically and
//       conceptually there's no need for both contexts: merge them!

// This is a terrible type; it's just Record<string, any> ... but we know that
// our user object *can't* have infinite properties
// TODO: Create a custom FusionAuthUser interface with all the properties we use
type FusionAuthUser = IFusionAuthContext['user'];

// NOTE: This is only exported so we can make fake user providers in Storybook
export const UserContext = createContext<{
  /**
   * The user's child organizations ("sub-tenants"())
   */
  childOrganizations: Organization[];

  /**
   * The current user (or at least FusionAuth's version of them)
   * NOTE: Bob has moved most of the FusionAuth user properties into the GraphQL
   *       type, so we really only use this to access roles (which is rare)
   */
  fusionAuthUser: FusionAuthUser | null;

  /**
   * Whether or not the user has logged in yet
   */
  isAuthenticated: boolean;

  /**
   * The user's main (ie. "parent") organization.
   */
  mainOrganization: Organization | null;

  /**
   * All of the current users parent organizations (some users have multiple
   * parent tenants, and choose which at login after they enter their email).
   * This is the list they choose from.
   */
  parentOrganizations: Organization[];

  /**
   * The user's roles (these come from the fusionAuthUser, but we expose them
   * diretly here so you don't have to use that object)
   */
  roles: string[];

  /**
   * The ID of the child organization the current user has selected (if any)
   */
  selectedOrganizationId: null;

  /**
   * Let's the login UI set the login email (so we can log the user in, or at
   * least get a list of tenants for them to choose from, if they're a
   * multi-tenant user).
   */
  setLoginEmail: Dispatch<(prevState: undefined) => undefined>;

  /**
   * Takes an organization name and switches the selectedOrganization to it.
   * If no name is provided, it switches back to the parent organization.
   */
  switchOrganization: (organizationName: string) => void;

  /**
   * The GraphQL representation of the User
   */
  user: User;
}>({
  childOrganizations: [],
  fusionAuthUser: null,
  isAuthenticated: false,
  mainOrganization: null,
  parentOrganizations: [],
  roles: [],
  selectedOrganizationId: null,
  setLoginEmail: () => {},
  switchOrganization: () => {},
  user: null
});

/**
 * Builds a function that switches the user to only see data from a specific
 * sub-organization.
 */
const buildSwitchOrganization =
  (mainOrganization, childOrgs, client, setSelectedOrganizationId) =>
  organizationName => {
    let id, name;
    if (!organizationName || organizationName === mainOrganization.name) {
      // Switching to the parent organization
      id = null;
      name = null;
      setSelectedOrganizationId(null);
    } else {
      // Switching to a child organization
      const organization = childOrgs.find(
        ({ name }) => name === organizationName
      );
      assert(organization, `No organization named "${name}" was found!`);

      name = organization.name;
      id = organization.id;
      setSelectedOrganizationId(id); // save in local storage
    }
    // NOTE: This gets used in ApolloProvider
    if (id) {
      window['INTERPRES_SELECTED_TENANT_ID'] = id;
    } else {
      delete window['INTERPRES_SELECTED_TENANT_ID'];
    }

    localStorage.setItem(localStorageKey, JSON.stringify(id));
    setTimeout(() => location.reload(), 500);
  };

export const UserProvider = ({
  children
}: PropsWithChildren): ReturnType<typeof UserContext.Provider> => {
  // Normally this code revolves around the fusionAuth user's email (ie. the
  // logged-in user's email) ... but, obviously, that won't work until the user
  // logs in.  This state holds their email so we can use it *to* login.
  const [loginEmail, setLoginEmail] = useState();
  const client = useApolloClient();
  const { fusionAuthUser, user, isAuthenticated } = useAuth();
  const email = loginEmail || user?.email;

  const childOrganizations = useChildOrganizations(!user);
  const parentOrganizations = useParentOrganizations(email);

  const mainOrganization = parentOrganizations?.find(
    ({ id }) => id === fusionAuthUser?.tid
  );

  const [selectedOrganizationId, setSelectedOrganizationId] = useState(
    JSON.parse(localStorage.getItem(localStorageKey))
  );

  useInformUserOfChildOrganizationOnReload(
    mainOrganization,
    selectedOrganizationId,
    childOrganizations
  );

  const switchOrganization = buildSwitchOrganization(
    mainOrganization,
    childOrganizations,
    client,
    setSelectedOrganizationId
  );
  useUpdateWhenStorageChangesInAnotherTab(setSelectedOrganizationId);

  return user?.email && !parentOrganizations ? null : (
    <UserContext.Provider
      value={{
        childOrganizations,
        fusionAuthUser,
        isAuthenticated,
        mainOrganization,
        parentOrganizations,
        roles: fusionAuthUser.roles,
        selectedOrganizationId,
        setLoginEmail,
        switchOrganization,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = buildUseContext(UserContext, `UserContext`);
