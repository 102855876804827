import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import classNames from 'classnames';
import assert from 'shared/utils/assert';
import css from './ChevronIcon.module.css';

export type Direction = 'right' | 'left' | 'up' | 'down';

/**
 * Renders a "Chevron icon" (ie. a caret or ^-like SVG), which can point in any
 * direction.
 * @example <Chevron Icon direction="up" /> // Looks somewhat like a "^"
 */
const ChevronIcon = ({
  className,
  direction,
  expanded,
  ...props
}: {
  className?: string;
  direction?: Direction;
  expanded?: boolean;
}) => {
  assert(
    direction == undefined || expanded == undefined,
    'ChevronIcon can only take a direction OR an expanded prop, not both!'
  );
  if (!direction && expanded !== undefined) {
    direction = expanded ? 'up' : 'down';
  }
  return (
    <Chevron
      className={classNames(css.chevron, css[direction], className)}
      {...props}
    />
  );
};

export default ChevronIcon;
