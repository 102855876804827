import * as FullStory from '@fullstory/browser';
import IdentitiesIcon from '@mui/icons-material/RecentActors';
import ShieldIcon from '@mui/icons-material/Shield';
import { useGlobalSearchContext } from 'App/GlobalSearch/GlobalSearchContext';
import GlobalSearchDialog from 'App/GlobalSearch/GlobalSearchDialog';
import { ReactComponent as LogoIcon } from 'assets/brand/icon-white.svg';
import { ReactComponent as AssetExposureIcon } from 'assets/icons/asset-exposure.svg';
import { ReactComponent as AssetInventoryIcon } from 'assets/icons/asset-inventory.svg';
import { ReactComponent as BrainIcon } from 'assets/icons/brain.svg';
import { ReactComponent as VisibilityIcon } from 'assets/icons/broadcast.svg';
import { ReactComponent as CampaignsIcon } from 'assets/icons/campaigns.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg';
import { ReactComponent as CompareIntegrationsIcon } from 'assets/icons/compare.svg';
import { ReactComponent as DefenseSurfaceIcon } from 'assets/icons/defense-surface.svg';
import { ReactComponent as DetectionsIcon } from 'assets/icons/detections.svg';
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg';
import { ReactComponent as InformationIcon } from 'assets/icons/information.svg';
import { ReactComponent as IntegrationsIcon } from 'assets/icons/integrations.svg';
import { ReactComponent as KeyboardIcon } from 'assets/icons/keyboard.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as SoftwareIcon } from 'assets/icons/software.svg';
import { ReactComponent as ThreatGroupIcon } from 'assets/icons/threat-group.svg';
import { ReactComponent as ThreatProfileIcon } from 'assets/icons/threat-model.svg';
import { ReactComponent as ThreatsIcon } from 'assets/icons/threats.svg';
import { ReactComponent as VulnerabilitiesIcon } from 'assets/icons/vulnerabilities.svg';
import { ReactComponent as WrenchIcon } from 'assets/icons/wrench.svg';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import { useAuth } from 'core/Auth';
import Mousetrap from 'mousetrap';
import { Divider, Kbd, useNotification } from 'reablocks';
import { FC } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useHotkeys } from 'reakeys';
import { Avatar } from 'shared/elements/Avatar';
import RequireRole from 'shared/user/RequireRole';
import { useUserContext } from 'shared/user/UserContext';
import MenuItem from './MenuItem';
import css from './Nav.module.css';
import OrganizationSwitchingMenu from './OrganizationSwitchingMenu';
import SubmenuItem, { UserSubmenuItem } from './SubmenuItem';
import { ReactComponent as DividerIcon } from './divider.svg';

// This doesn't work, sadly, so instead we have to hard-code the color here
// import config from '../../../../tailwind.config.js';
const circledLetterColor = '#06B3FF'; // blue400

const USE_NO_CHAT_UI = import.meta.env.VITE_IS_ON_PREMISE === 'true';

export const Nav: FC = () => {
  let { show } = useIntercom();
  if (location.host.includes('localhost')) {
    show = () => alert(`If we weren't in dev, you'd see the help pop-over now`);
  }
  const { logout } = useAuth();
  const { user } = useUserContext();

  const { notify } = useNotification();
  const { open: showGlobalSearch } = useGlobalSearchContext();

  useHotkeys([
    {
      name: 'Help',
      keys: 'h',
      description: 'Open the help menu',
      callback: () => show()
    },
    {
      name: 'Capture Session',
      keys: 'mod+/',
      description: 'Capture the session for debugging',
      callback: () => {
        const sessionURL = FullStory?.getCurrentSessionURL(true);
        if (sessionURL) {
          copy(sessionURL);
          notify(`Copied ${sessionURL} to your clipboard.`);
        }
      }
    }
  ]);

  return (
    <nav className={css.container}>
      <div className={css.top}>
        <MenuItem
          buttonClassName={css.topButton}
          icon={<LogoIcon className={css.logo} />}
          link="/"
        />
        <DividerIcon className={css.divider} />
        <MenuItem
          icon={<ThreatsIcon />}
          isActivePatterns={[
            '/campaigns/*',
            '/software/*',
            '/techniques/*',
            '/threat-groups/*',
            '/threat-profiles/*'
          ]}
          name="Threat Exposure"
          submenu={
            <>
              <SubmenuItem
                icon={CampaignsIcon}
                label="Campaigns"
                link="/campaigns"
              />
              <SubmenuItem
                icon={BrainIcon}
                label="Techniques"
                link="/techniques"
              />
              <SubmenuItem
                icon={SoftwareIcon}
                label="Software"
                link="/software"
              />
              <SubmenuItem
                icon={ThreatGroupIcon}
                label="Threat Groups"
                link="/threat-groups"
              />
              <SubmenuItem
                icon={ShieldIcon}
                label="ATT&CK Matrix"
                link="/attack-matrix"
              />
              <SubmenuItem
                icon={ThreatProfileIcon}
                label="Threat Profile"
                link="/threat-profiles"
              />
            </>
          }
        />
        <DividerIcon className={css.divider} />
        <MenuItem
          icon={<DefenseSurfaceIcon />}
          isActivePatterns={[
            '/controls/*',
            '/detections/*',
            '/integrations/*',
            '/recommendations/*',
            '/sources/*',
            '/product-comparison/*',
            '/visibility/*'
          ]}
          name="Defense Surface"
          submenu={
            <>
              <SubmenuItem
                icon={VisibilityIcon}
                label="Visibility"
                link="/visibility"
              />
              <SubmenuItem
                icon={DetectionsIcon}
                label="Detections"
                link="/detections"
              />
              <SubmenuItem
                icon={IntegrationsIcon}
                label="Integrations"
                link="/integrations"
              />
              <SubmenuItem
                icon={CompareIntegrationsIcon}
                label="Product Comparison"
                link="/product-comparison"
              />
            </>
          }
        />
        <DividerIcon className={css.divider} />
        <MenuItem
          icon={<AssetExposureIcon />}
          isActivePatterns={[
            '/asset/*',
            '/asset-inventory/*',
            '/platforms/*',
            '/vulnerabilities/*'
          ]}
          name="Asset Exposure"
          submenu={
            <>
              <SubmenuItem
                icon={VulnerabilitiesIcon}
                label="Vulnerabilities"
                link="/vulnerabilities"
              />
              <SubmenuItem
                icon={AssetInventoryIcon}
                label="Assets Inventory"
                link="/asset"
              />
              <SubmenuItem
                icon={IdentitiesIcon}
                label="Identities"
                link="/identities"
              />
            </>
          }
        />
        <DividerIcon className={css.divider} />
        <GlobalSearchDialog />
        <MenuItem
          icon={<SearchIcon />}
          name="Global Search"
          onClick={showGlobalSearch}
        />
      </div>
      <div className={css.push} />
      <div className={classNames(css.bottom, { [css.old]: USE_NO_CHAT_UI })}>
        <MenuItem
          icon={<HelpIcon />}
          modifiers={{
            offset: {
              offset: '10px, 25px'
            }
          }}
          name="Help"
          onClick={show}
        />
        <DividerIcon className={css.divider} />
        <MenuItem
          icon={<WrenchIcon />}
          isActivePatterns={['/settings/*']}
          link="/settings"
          name="Settings"
          triggerClassName={css.settingsTooltip}
        />
        {!USE_NO_CHAT_UI && (
          <MenuItem
            icon={<ChatIcon />}
            isActivePatterns={['/assistant/*', '/aichat/*']}
            link="/aichat/welcome"
            name="AI Chat"
            triggerClassName={css.settingsTooltip}
          />
        )}
        <DividerIcon className={css.divider} />
        <RequireRole role="ReadChildTenant">
          <OrganizationSwitchingMenu />
        </RequireRole>

        <MenuItem
          buttonClassName={css.avatarBtn}
          icon={<Avatar user={user} />}
          submenuClass={css.userMenu}
          submenu={
            <>
              <UserSubmenuItem user={user} />
              <SubmenuItem
                alwaysWhite
                icon={InformationIcon}
                label="Docs"
                onClick={() =>
                  window.open('http://docs.interpres.io/', '__blank')
                }
              />
              <SubmenuItem
                alwaysWhite
                icon={KeyboardIcon}
                label="Keyboard Shortcuts"
                onClick={() => Mousetrap.trigger('SHIFT+?')}
                shortcut={
                  <Kbd
                    keycode="shift+?"
                    title="Shift + ? to open hotkey shortcuts"
                  />
                }
              />
              <Divider disableMargins />
              <SubmenuItem icon={LogoutIcon} label="Logout" onClick={logout} />
            </>
          }
        />
      </div>
    </nav>
  );
};
