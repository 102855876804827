import RollerShadesIcon from '@mui/icons-material/RollerShades';
import { ReactComponent as ContentIcon } from 'assets/icons/content.svg';
import { ReactComponent as IntegrationIcon } from 'assets/icons/integrations.svg';
import { ReactComponent as PlatformIcon } from 'assets/icons/platform.svg';
import { ReactComponent as PriorityIcon } from 'assets/icons/priority.svg';
import { ReactComponent as TacticsIcon } from 'assets/icons/tactics.svg';
import classNames from 'classnames';
import { Stack } from 'reablocks';
import { ActiveFilterChip } from 'shared/utils/ActiveFilterChip';
import { coverageOptions } from './FilterTechniques';
import css from './Techniques.module.css';
import {
  MAX_COVERAGE,
  MAX_PRIORITY,
  MIN_COVERAGE,
  MIN_PRIORITY
} from './utils';

const TechniquesActiveFilters = ({
  className,
  filter,
  setFilter
}: {
  className?: string;
  filter: any;
  setFilter: (filter: any) => void;
}) => (
  <Stack className={classNames(css.activeFilters, className)}>
    {filter?.selectedCoverage && filter?.selectedCoverage !== 'content' && (
      <ActiveFilterChip
        icon={<RollerShadesIcon />}
        label="Selected Coverage"
        onDelete={() => setFilter({ ...filter, selectedCoverage: 'content' })}
        value={coverageOptions[filter?.selectedCoverage].split(' ')[0]}
      />
    )}
    {filter?.priority &&
      filter?.priority !== [MIN_PRIORITY, MAX_PRIORITY]?.toLocaleString() && (
        <ActiveFilterChip
          icon={<PriorityIcon />}
          label="Priority"
          value={filter?.priority?.split(',').join(' to ')}
          onDelete={() =>
            setFilter({
              ...filter,
              priority: [MIN_PRIORITY, MAX_PRIORITY]?.toLocaleString()
            })
          }
        />
      )}
    {filter?.coverage &&
      filter?.coverage !== [MIN_COVERAGE, MAX_COVERAGE]?.toLocaleString() && (
        <ActiveFilterChip
          icon={<ContentIcon />}
          label="Content"
          value={filter?.coverage?.split(',').join(' to ')}
          onDelete={() =>
            setFilter({
              ...filter,
              coverage: [MIN_COVERAGE, MAX_COVERAGE]?.toLocaleString()
            })
          }
        />
      )}
    {filter?.platformNames?.length > 0 && (
      <ActiveFilterChip
        icon={<PlatformIcon />}
        label="Platforms"
        value={filter?.platformNames?.split(',')?.[0]}
        suffix={
          filter?.platformNames?.split(',')?.length > 1 &&
          `+${filter?.platformNames?.split(',')?.length - 1}`
        }
        onDelete={() => setFilter({ ...filter, platformNames: '' })}
      />
    )}
    {filter?.tacticNames?.length > 0 && (
      <ActiveFilterChip
        icon={<TacticsIcon />}
        label="Tactics"
        value={filter?.tacticNames?.split(',')?.[0]}
        suffix={
          filter?.tacticNames?.split(',')?.length > 1 &&
          `+${filter?.tacticNames?.split(',')?.length - 1}`
        }
        onDelete={() => setFilter({ ...filter, tacticNames: '' })}
      />
    )}
    {filter?.integrationNames?.length > 0 && (
      <ActiveFilterChip
        icon={<IntegrationIcon />}
        label="Integrations"
        value={filter?.integrationNames?.split(',')?.[0]}
        suffix={
          filter?.integrationNames?.split(',')?.length > 1 &&
          `+${filter?.integrationNames?.split(',')?.length - 1}`
        }
        onDelete={() => setFilter({ ...filter, integrationNames: '' })}
      />
    )}
  </Stack>
);
export default TechniquesActiveFilters;
